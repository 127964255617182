export default {
  background: {
    default: "#FFFEF8",
  },

  text: {
    main: "#21242B",
    focus: "#7b809a",
    bold: "#5A5EA6",
  },

  text2:{
    main: "#C4C6FA",
    focus: "#C4C6FA",
    bold: "#C4C6FA",
  },

  text3:{
    main: "#FCFAF1",
    focus: "#FCFAF1",
    bold: "#FCFAF1",
  },

  text4:{
    main: "#D9DAEB",
    focus: "#D9DAEB",
    bold: "#D9DAEB",
  },

  text5:{
    main: "#FFE4A2",
    focus: "#FFE4A2",
    bold: "#FFE4A2",
  },

  teamtitle:{
    main: "#170F49",
    focus: "#170F49",
    bold: "#170F49",
  },

  team:{
    main: "#6F6C90",
    focus: "#6F6C90",
    bold: "#6F6C90",
  },

  grey:{
    main: "#818181",
    focus: "#818181",
    bold: "#818181",
  },

  quote:{
    main: "#818181",
    focus: "#818181",
    bold: "#818181",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },


  primary: {
    main: "#5D5DFB",
    focus: "#5D5DFB",
  },


  secondary: {
    main: "#DB6639",
    focus: "#DB6639",
  },

  terciary: {
    main: "#F9DA90",
    focus: "#F9DA90",
  },

  info: {
    main: "#8fcdbf",
    focus: "#8fcdbf",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#07092B",
    focus: "#07092B",
  },

  menu:{
    main: "#170F49",
    focus: "#170F49",
    bold: "#170F49",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#5D5DFB",
      state: "#5D5DFB",
    },

    secondary: {
      main: "#DB6639",
      state: "#DB6639",
    },

    terciary: {
      main: "#F9DA90",
      state: "#F9DA90",
    },

    info: {
      main: "#cbf7ed",
      state: "#8fcdbf",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
