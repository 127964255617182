import { Fragment, useState, useEffect } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';

// Material Kit 2 PRO React examples
import DefaultNavbarDropdown from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarDropdown';
import DefaultNavbarMobile from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarMobile';

// Material Kit 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

import { useUIController } from 'contexts/ui';

import Logo from 'assets/images/Logo.png';
import { analyticsTag } from 'helpers/coreHelper';

const BRAND_NAME = 'Yodai';

function DefaultNavbar({ transparent, light, action, sticky, relative, center }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const toggleMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
    */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  const headerRoutes = [
    // {
    //   name: 'Home',
    //   key: 'home',
    //   route: '/',
    // },
    // {
    //   name: 'Technology',
    //   key: 'technology',
    //   route: '/technology',
    // },
    // {
    //   name: 'Foundation',
    //   key: 'foundation',
    //   route: '/foundation',
    // },
    // {
    //   name: 'Portfolio',
    //   key: 'portfolio',
    //   route: '/portfolio',
    // },
  ];

  const renderNavbarItems = headerRoutes.map(({ name, icon, href, route }) => (
    <DefaultNavbarDropdown
      key={name}
      name={name}
      icon={icon}
      href={href}
      route={route}
      light={light}
    />
  ));

  return (
    <CustomContainer sx={sticky ? { position: 'sticky', top: 0, zIndex: 10 } : null}>
      <MKBox
        py={6}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        width="100%"
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? 'none' : `saturate(200%) blur(30px)`,
        })}
        borderBottom={'1px solid #07092B'}
      >
        <MKBox
          display="flex"
          justifyContent={{ xs: 'space-between', md: 'start' }}
          alignItems="center"
        >
          {/* Menu Icon */}
          <MKBox
            display={{ xs: 'flex', sm: 'flex', lg: 'none' }}
            lineHeight={0}
            py={1.5}
            pr={2.5}
            color={transparent ? 'primary' : 'inherit'}
            sx={{ cursor: 'pointer' }}
            onClick={toggleMobileNavbar}
            order="0"
          >
            <Icon fontSize="default">{mobileNavbar ? 'close' : 'menu'}</Icon>
          </MKBox>

          <MKBox
            component={Link}
            to="/"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
            display="flex"
            order={{ xs: 1, sm: 1, md: 0 }}
            style={{ maxWidth: 65 }}
          >
            <MKBox
              component="img"
              src={Logo}
              alt={BRAND_NAME}
              position="relative"
              zIndex={1}
              sx={{
                // height: 40,
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                },
              }}
            />
          </MKBox>
          <MKBox color="inherit" width="100%" display={{ xs: 'none', lg: 'flex' }}>
            {renderNavbarItems}
          </MKBox>

          {/* Apply button */}
          <MKBox ml={{ lg: 0 }}>
            <MKButton
              variant="contained"
              borderRadius={0}
              onClick={() => {
                analyticsTag({
                  event: 'purchase',
                });

                window.location = 'https://wa.me/+5491164828027?text=Hola Yodai';

                // router.push('/apply');
              }}
              component="a"
              target="_blank"
              rel="noreferrer"
              color={'dark'}
              size="large"
              style={{ minWidth: 150 }}
            >
              {'Lets talk'}
            </MKButton>
          </MKBox>
        </MKBox>
        <MKBox
          bgColor={transparent ? 'white' : 'transparent'}
          shadow={transparent ? 'lg' : 'none'}
          borderRadius="xl"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              routes={headerRoutes}
              open={mobileNavbar}
              toggleNavbar={toggleMobileNavbar}
            />
          )}
        </MKBox>
      </MKBox>
    </CustomContainer>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
