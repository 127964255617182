import { useEffect, useRef, useState } from 'react';
import Rellax from 'rellax';
import { useLocation } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { ENVIRONMENT } from 'config/appConfig';

import { Formik, Form } from 'formik';
import Swal from 'sweetalert2';

// Routes
import footerRoutes from 'footer.routes';

import { useUIController, setLayout, setCTA } from 'contexts/ui';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Sections
import Companies from './sections/Companies';
import ContactUs from '../shared/sections/ContactUs';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

function Portfolio() {
  return (
    <>
      <Companies />
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Portfolio;
