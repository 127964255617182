import { useRef, useEffect } from 'react';
import Rellax from 'rellax';

// @mui material components
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import StartUpBlogCard from 'DS/complex/Cards/BlogCards/StartUpBlogCard';

import points from 'assets/images/points-services.png';

// Services Images
import DrGeaBanner from 'assets/images/banners/banner-drgea.png';
import AconcaguaBanner from 'assets/images/banners/banner-aconcagua.jpg';
import NippyBanner from 'assets/images/banners/banner-nippy.webp';
import SomosFitBanner from 'assets/images/banners/banner-somosfit.jpg';
import MaiaBanner from 'assets/images/banners/banner-maia.png';
import DendroBanner from 'assets/images/banners/banner-dendro.png';
import NoPausaBanner from 'assets/images/banners/banner-no-pausa.png';
import EnliteBanner from 'assets/images/banners/banner-riat.png';
import QxmBanner from 'assets/images/banners/banner-qxm.png';
import ImpulsumBanner from 'assets/images/banners/banner-impulsum.png';

import DrGea from 'assets/images/logos/dr-gea.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import Dendro from 'assets/images/logos/logo-dendro.png';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Enlite from 'assets/images/logos/logo-enlite.jpg';
import Qxm from 'assets/images/logos/logo-qxm.png';
import Impulsum from 'assets/images/logos/logo-impulsum.png';

function Companies() {
  const ArrowStyles = {
    position: 'relative',
    zIndex: '-9',
  };

  const companies = [
    {
      image: DrGeaBanner,
      title: 'DrGea',
      logo: DrGea,
      description:
        'DrGea provides professionally-guided natural treatments (Cannabis & others) and products.',
      fact: 'Cool fact: 1st all-in-one and ent-to-end Canna-tech platform in Latam',
      action: {
        type: 'external',
        route: 'https://www.drgea.com/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/drgea/',
      },
    },
    {
      image: AconcaguaBanner,
      title: 'Aconcagua',
      logo: Aconcagua,
      description:
        'Aconcagua provides lenders the latest tech-based collaterals. This new paradigm of lending will: 1) increase the base of clients; 2) increase the credit size; 3) decrease the interest rate. ',
      fact: 'Cool fact: 2x fintech founder',
      action: {
        type: 'external',
        route: 'https://www.aconcagua.finance/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/aconcagua-llc/about/',
      },
    },
    {
      image: NippyBanner,
      title: 'Nippy',
      logo: Nippy,
      description:
        'Nippy connects the (under-served) gig economy with services, benefits and opportunities.',
      // fact: 'Cool fact: XX% MoM growth',
      action: {
        type: 'external',
        route: 'https://nippy.la/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/nippy-latam/',
        twRoute: 'https://twitter.com/NippyLatam',
      },
    },
    {
      image: SomosFitBanner,
      title: 'SomosFit',
      logo: SomosFit,
      description:
        'SomosFit offers the simplest way to have a personalized, sustainable and healthy diet. Unlike most competitors, it’s food delivery covers all the meals and are customized to each user by his/her nutritionist.',
      fact: 'Cool fact:  5 countries in Latam & Europe',
      action: {
        type: 'external',
        route: 'https://somosfit.com/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/somosfitlatam/',
      },
    },
    {
      image: MaiaBanner,
      title: 'MAIA',
      logo: Maia,
      description:
        'MAIA enables employers and other "community creators" to improve their community’s wellness.',
      fact: 'Cool fact: 1st startup created by Koi Company Builder.',
      action: {
        type: 'external',
        route: 'https://himaia.io/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/himaia/',
      },
    },
    {
      image: DendroBanner,
      title: 'Dendro',
      logo: Dendro,
      description: 'Eficiencia para tu empresa, tiempo para el planeta.',
      action: {
        type: 'external',
        route: 'http://dendrolatam.com/es/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/dendro-latam/',
      },
    },
    {
      image: NoPausaBanner,
      title: 'No Pausa',
      logo: NoPausa,
      description: 'Construyendo una nueva mirada sobre el climaterio menopausia en el siglo XXI.',
      action: {
        type: 'external',
        route: 'https://menocheck.no-pausa.com/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/no-pausa-menopausia/',
        igRoute: 'https://www.instagram.com/nopausaig/',
      },
    },
    {
      image: Enlite,
      title: 'Enlite',
      logo: Enlite,
      description: 'Iluminamos el camino de su recuperación',
      action: {
        type: 'external',
        route: 'https://riat.app/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/riatlimited',
        igRoute: 'https://www.instagram.com/riatbrasil/',
      },
    },
    {
      image: QxmBanner,
      title: 'Qxm',
      logo: Qxm,
      description:
        'Worker tech de impacto con Market place de servicios de oficios 100% gratuito, transparente e inclusivo que vincula oferta permanente de trabajadores formalizados con clientes que demandan dichos servicios.',
      action: {
        type: 'external',
        route: 'https://www.qxm.com.ar/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/qxm/',
        igRoute: 'https://www.instagram.com/qxm.oficial/',
      },
    },
    {
      image: ImpulsumBanner,
      title: 'Impulsum',
      logo: Impulsum,
      description:
        'Conectamos el propósito de empresas con el de sus colaboradores, para promover la cultura y el impacto positivo.',
      action: {
        type: 'external',
        route: 'https://www.solidario.club/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/clubsolidario/',
        igRoute: 'https://www.instagram.com/club.solidario/',
      },
    },
  ];

  const BackgroundPoints = {
    position: 'absolute',
    top: '260px',
    right: '0',
  };

  const BackgroundPoints2 = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: -1,
  };

  const rellaxRef = useRef();

  useEffect(() => {
    new Rellax(rellaxRef.current, {
      speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);

  const rellaxPointsRef = useRef();

  useEffect(() => {
    new Rellax(rellaxPointsRef.current, {
      speed: -4,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);

  const companyCluster = [];

  for (let i = 0; i < companies.length; i += 3) {
    const chunk = companies.slice(i, i + 3);
    companyCluster.push(chunk);
  }

  return (
    <MKBox
      width="100%"
      pb={4}
      sx={{
        position: 'relative',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CustomContainer>
        <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Every dream begins with a <b>dreamer</b>
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>

        {companyCluster.map((chunk, idx) => (
          <Grid
            key={idx}
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            pb={{ xs: 0, sm: 8 }}
            style={{ marginTop: 20 }}
          >
            {chunk.map((company, innerIdx) => (
              <Grid item xs={12} lg={3}>
                <StartUpBlogCard {...company} key={innerIdx} />
              </Grid>
            ))}
          </Grid>
        ))}
      </CustomContainer>
    </MKBox>
  );
}

export default Companies;
