import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images

import aws from 'assets/images/logos/aws.png';
import law from 'assets/images/logos/Lawiblanco.png';
import explorer from 'assets/images/logos/Explorer.png';
import montain from 'assets/images/logos/Montain.png';

import bg_footer from 'assets/images/bg-logos.png';

function LogoAreaOne() {
  const Background = {
    backgroundImage: `url(${bg_footer})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  };
  return (
    <MKBox
      py={6}
      sx={{
        ...Background,
      }}
    >
      <CustomContainer>
        <Grid
          container
          item
          justifyContent="center"
          xs={8}
          sx={{ mx: 'auto', textAlign: 'center', marginBottom: '16px' }}
        >
          <MKTypography variant="h6" color="text4">
            They trust us:
          </MKTypography>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ mt: 3 }}
          justifyContent="center"
          sx={{ gap: { xs: '12px', sm: '24px', md: '64px' } }}
          sx={{
            display: {
              sm: 'block',
              md: 'flex',
            },
          }}
        >
          <Grid item xs={12} sm={12} md={3} justifyContent="center" display="flex">
            <MKBox component="img" src={law} alt="law" width="148px" height="48px" />
          </Grid>
          <Grid item xs={12} sm={12} md={3} justifyContent="center" display="flex">
            <MKBox component="img" src={aws} alt="aws" width="148px" height="48px" />
          </Grid>
          <Grid item xs={12} sm={12} md={3} justifyContent="center" display="flex">
            <MKBox component="img" src={explorer} alt="explorer" width="148px" height="48px" />
          </Grid>
          <Grid item xs={12} sm={12} md={3} justifyContent="center" display="flex">
            <MKBox component="img" src={montain} alt="montain" width="148px" height="48px" />
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default LogoAreaOne;
