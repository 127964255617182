// import * as firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

import { FirebaseConfig } from './firebaseConfig';

const app = initializeApp(FirebaseConfig);

const storage = getStorage();
const dbAux = getFirestore(app);

const authAux = getAuth();

export const db = dbAux;

export const authRef = authAux;
export const auth = getAuth;

export const getDocumentById = async function ({ path, id }) {
  const docRef = doc(db, path, id);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) return null;

  return { ...docSnap.data(), id };
};

export const subscribeToCollection = function ({ path, onUpdate, queryConstrains }) {
  let qc = null;

  if (queryConstrains)
    qc = queryConstrains.map((constrain) => {
      return where(constrain.fieldPath, constrain.opStr, constrain.value);
    });

  let q = null;

  if (qc) q = query(collection(db, path), ...qc);
  else q = query(collection(db, path));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((resultDoc) => {
      items.push(resultDoc.data());
    });

    onUpdate(items);
  });

  return unsubscribe;
};

export const uploadFileWithProgressTracking = async (
  filename,
  uploadUri,
  callbackSuccess,
  callbackError
) => {
  // Success handler with SUCCESS is called multiple times on Android. We need work around that to ensure we only call it once
  let finished = false;

  storage
    .ref(filename)
    .putFile(uploadUri)
    .on(
      storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        if (snapshot.state === storage.TaskState.SUCCESS) {
          if (finished === true) {
            return;
          }
          finished = true;
        }
        callbackSuccess(snapshot, storage.TaskState.SUCCESS);
      },
      callbackError
    );
};
