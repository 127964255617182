/* eslint-disable no-debugger */
/* eslint-disable no-console */

// grants[ {enterpriseId, rols: [ENTERPRISE_SALES, ENTERPRISE_ADMIN]}]
// appRols: decodedToken.appRols, // [APP_ADMIN]

export const userIsGranted = function ({
  userAppRols,
  userEnterpriseGrants,
  targetAppRols,
  targetEnterpriseRols,
  enterpriseId,
}) {
  if (
    userAppRols &&
    targetAppRols &&
    userAppRols.find((element) => {
      return targetAppRols.includes(element);
    })
  )
    return true;

  if (targetEnterpriseRols) {
    const enterpriseGrant = userEnterpriseGrants.find((grant) => {
      return grant.enterpriseId === enterpriseId;
    });

    if (enterpriseGrant) {
      const rol = enterpriseGrant.rols.find((element) => {
        return targetEnterpriseRols.includes(element);
      });

      if (rol) return true;
    }
  }

  return false;
};
