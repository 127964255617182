// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Instagram from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

// Material Kit 2 PRO React components
import MKTypography from 'DS/components/MKTypography';

// Images
import logoCT from 'assets/images/Iso-logo.png';

import { APPLY_INCUBATOR_URL, APPLY_BOOTCAMP_URL } from './helpers/coreHelper';

const date = new Date().getFullYear();

export default {
  brand: {
    name: 'Yodai',
    image: logoCT,
    description: 'The one-stop shop for all technology needs',
    route: '/',
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      link: 'https://www.linkedin.com/company/yodaitech/',
    },
    {
      icon: <Instagram />,
      link: 'https://www.instagram.com/yodai.tech/',
    },
    {
      icon: <YouTubeIcon />,
      link: 'https://www.youtube.com/@yodaitech/',
    },
  ],
  contact: [
    {
      icon: <EmailOutlinedIcon />,
      text: 'info@yodai.tech',
      link: 'mailto:info@yodai.tech',
    },
    {
      icon: <PhoneOutlinedIcon />,
      text: '(+54) 1164828027',
    },
    {
      icon: <PlaceOutlinedIcon />,
      text: 'Buenos Aires',
    },
  ],
  menus: [
    {
      name: 'Navigation',
      items: [
        { name: 'AI', href: APPLY_BOOTCAMP_URL },
        { name: 'Development', href: APPLY_INCUBATOR_URL },
        { name: 'Consulting', href: APPLY_INCUBATOR_URL },
      ],
    },
  ],
  submenus: [
    {
      name: 'Terms & Conditions',
      href: '/',
    },
    { name: 'Privacy', href: '/' },
    { name: 'Cookie Settings', href: '/' },
  ],
  action: {
    text: 'We’d love to hear your idea',
    textLink: 'Chat with us',
    link: 'https://wa.me/+5491164828027?text=Hola Yodai',
  },
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      <MKTypography
        component="a"
        href="https://yodai.tech"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        {' '}
        &copy; Yodai. All rights reserved {date}{' '}
      </MKTypography>
      .
    </MKTypography>
  ),
};
