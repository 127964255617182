

// Material Kit 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark } = colors;

const baseProperties = {
  //fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: '"Manrope", sans-serif',
  fontFamily2: '"DM Sans", sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30),
  fontLineLG: pxToRem(30),
};

const baseHeadingProperties = {
  color: dark.main,
  fontWeight: baseProperties.fontWeightBold,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(44),
    lineHeight: pxToRem(56),
    ...baseHeadingProperties,
    fontWeight: baseProperties.fontWeightRegular,
    '@media (max-width: 768px)': {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(40),
    },
  },

  h2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    fontWeight: baseProperties.fontWeightMedium,
    ...baseHeadingProperties,
    '@media (max-width: 768px)': {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
    }
  },

  h3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h6: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  titleForm: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(32),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },

  services: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    ...baseHeadingProperties,
    fontWeight: baseProperties.fontWeightExtraBold,
    '@media (max-width: 768px)': {
      fontSize: pxToRem(14),
    },
  },


  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
  },

  subtitle3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(30),
    display:'block',
  },

  body1: {
    fontFamily: baseProperties.fontFamily2,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.fontLineLG,
    '@media (max-width: 768px)': {
      fontSize: baseProperties.fontSizeMD,
      lineHeight: baseProperties.fontLineMD,
    },
  },

  flywheel: {
    fontFamily: baseProperties.fontFamily2,
    fontSize: pxToRem(14),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(14),
    '@media (max-width: 768px)': {
      fontSize: baseProperties.fontSizeMD,
      lineHeight: baseProperties.fontLineMD,
    },
  },

  collapsetitle: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(22),
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.fontLineLG,
  },

  collapse: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: baseProperties.fontLineLG,
  },

  body2: {
    fontFamily: baseProperties.fontFamily2,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  footerList: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(16),
    '@media (max-width: 768px)': {
      fontSize: baseProperties.fontSizeSM,
      lineHeight: baseProperties.fontLineSM,
    },
  },

  teamname: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(16),
    display:'block',
  },

  teampos: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(18),
    display:'block',
  },

  quote: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(18),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(28),
    display:'block',
    '@media (max-width: 768px)': {
      fontSize: baseProperties.fontSizeMD,
      lineHeight: baseProperties.fontLineMD,
    },
  },

  twitter: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(12),
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(18),
    display:'block',
  },

  name: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(14),
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(24),
    display:'block',
  },



  menu: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: pxToRem(20),
  },


  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  d1: {
      fontFamily: baseProperties.fontFamily,
      fontSize: pxToRem(64),
      lineHeight: pxToRem(80),
      textDecoration: 'underline',
      ...baseHeadingProperties,
      '@media (max-width: 768px)': {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(40),
      },
  },

  d2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(48),
    lineHeight: 1.25,
    ...baseHeadingProperties,
    '@media (max-width: 768px)': {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(30),
    },
  },

  d3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(55),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
    '@media (max-width: 768px)': {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(44),
    },
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    "2xl": baseProperties.fontSize2XL,
    "3xl": baseProperties.fontSize3XL,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
};

export default typography;
