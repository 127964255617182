import { authRef } from 'config/firebase';

export const getUserWithToken = function () {
  return new Promise((resolve, reject) => {
    authRef.onAuthStateChanged((user) => {
      if (!user) resolve(null);
      else
        user
          .getIdToken()
          .then((token) => {
            return resolve({ token, user });
          })
          .catch((e) => {
            throw e;
          });
    });
  });
};

export const analyticsTag = function (data) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(data);
};

export const APPLY_MAIN_URL = 'https://wa.me/+5491164828027?text=Hola Yodai';
export const APPLY_BOOTCAMP_URL = 'https://wa.me/+5491164828027?text=Hola Yodai';
export const APPLY_INCUBATOR_URL = 'https://wa.me/+5491164828027?text=Hola Yodai';
export const APPLY_ACCELERATOR_URL = 'https://wa.me/+5491164828027?text=Hola Yodai';
