/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// The CFOStartup Material main context
const OnboardingContext = createContext();

// Setting custom name for the context which is visible on react dev tools
OnboardingContext.displayName = 'OnboardingContext';

// CFOStartup React reducer
function reducer(state, action) {
  switch (action.type) {
    case 'ONBOARDING_INFO': {
      return { ...state, onboardingInfo: action.value };
    }
    case 'ONBOARDING_ACTIVE_STEP': {
      return { ...state, onboardingActiveStep: action.value };
    }
    case 'ONBOARDING_LEAD_ID': {
      return { ...state, leadId: action.value };
    }
    case 'ONBOARDING_SELECTED_PRODUCT': {
      return { ...state, onboardingSelectedProduct: action.value };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// CFOStartup React context provider
function OnboardingControllerProvider({ children }) {
  const initialState = {
    onboardingInfo: {},
    onboardingActiveStep: 0,
    leadId: null,
    onboardingSelectedProduct: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return (
    <OnboardingContext.Provider value={[controller, dispatch]}>
      {children}
    </OnboardingContext.Provider>
  );
}

// CFOStartup React custom hook for using context
function useOnboardingController() {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error(
      'useOnboardingController should be used inside the OnboardingControllerProvider.'
    );
  }

  return context;
}

// Typechecking props for the UIControllerProvider
OnboardingControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setOnboardingInfo = (dispatch, value) => dispatch({ type: 'ONBOARDING_INFO', value });
const setOnboardingActiveStep = (dispatch, value) =>
  dispatch({ type: 'ONBOARDING_ACTIVE_STEP', value });
const setOnboardingLeadId = (dispatch, value) => dispatch({ type: 'ONBOARDING_LEAD_ID', value });
const setOnboardingSelectedProduct = (dispatch, value) =>
  dispatch({ type: 'ONBOARDING_SELECTED_PRODUCT', value });

export {
  OnboardingControllerProvider,
  useOnboardingController,
  setOnboardingInfo,
  setOnboardingActiveStep,
  setOnboardingLeadId,
  setOnboardingSelectedProduct,
};
