// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
import bgImage from 'assets/images/cta.png';
import { analyticsTag } from 'helpers/coreHelper';

function Vision() {
  const Background = {
    background: '#FFFEF8',
    position: 'relative',
    height: '100%',
    zIndex: '0',
    '&::before': {
      content: '""',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '0',
      left: '0',
      background: '#DB6639',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
    '&::after': {
      content: '""',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '0',
      right: '0',
      background: '#F9DA90',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
  };

  const Title = {
    display: 'flex',
    background: '#5D5DFB',
    position: 'relative',
    flexDirection: 'column',
    height: 'auto',
    width: { xs: '100%', md: 'auto' },
    py: '120px',
    px: { xs: '32px', md: '90px' },
    '&::before': {
      content: '""',
      display: { xs: 'block', md: 'none' },
      position: 'absolute',
      top: '0',
      left: '-16px',
      background: 'inherit',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
    '&::after': {
      content: '""',
      display: { xs: 'block', md: 'none' },
      position: 'absolute',
      top: '0',
      right: '-16px',
      background: 'inherit',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
  };

  const Description = {
    display: 'flex',
    position: 'relative',
    px: { xs: '16px', md: '100px' },
    py: { xs: '16px' },
    mr: { xs: '74px' },
    width: 'auto',
    alignItems: 'center',
    '&::after': {
      content: '""',
      display: { xs: 'block', md: 'none' },
      position: 'absolute',
      top: '0',
      right: '-90px',
      background: '#F9DA90',
      width: '90px',
      height: '100%',
      zIndex: '1',
    },
  };

  return (
    <MKBox
      display="flex"
      position="relative"
      sx={{
        ...Background,
      }}
    >
      <CustomContainer>
        <MKBox
          flexDirection="row"
          mx={4}
          display="flex"
          alignItems="strech"
          height="100%"
          sx={{
            background: '#FFFEF8',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            gap: { xs: '0px', md: '16px' },
            mx: { xs: '0' },
          }}
        >
          <MKBox
            sx={{
              ...Title,
            }}
          >
            <MKTypography variant="flywheel" color="white">
              · OUR VISION
            </MKTypography>
            <MKTypography variant="h1" color="white" mb={1} sx={{ textWrap: 'nowrap' }}>
              <b>Why</b> we do it
            </MKTypography>
          </MKBox>
          <MKBox sx={{ ...Description }}>
            <MKTypography variant="body1" color="text" mb={1}>
              Together, we envision a technology team dedicated to serving purpose-driven
              businesses, fueling a collective mission to create a better world. By leveraging our
              expertise and innovation, we empower companies to make a positive impact on society
              and the environment
            </MKTypography>
          </MKBox>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Vision;
