/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://koiventures.tech/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://koiventures.tech)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
