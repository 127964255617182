import { useState } from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Pricing page components
import FaqCollapse from 'pages/faq/components/FaqCollapse';

function Faq() {
  const [collapse, setCollapse] = useState(false);

  const Background = {
    backgroundColor: '#FFFFFF',
  };

  return (
    <MKBox
      component="section"
      py={6}
      sx={{
        ...Background,
      }}
    >
      <CustomContainer>
        <MKBox pt={3} pb={8} textAlign="center">
          <MKTypography variant="d3" color="secondary">
            Any doubts?
          </MKTypography>
        </MKBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <MKBox py={2} px={3} ml={3}>
              <FaqCollapse
                title="¿QUÉ ES Sandbox?"
                open={collapse === 1}
                onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
              >
                Sandbox te acompaña en tu búsqueda del bienestar. Buscamos acompañarte de la forma
                que vos necesites. Por eso ofrecemos 4 acompañamientos principales:
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Sesiones 1:1 con tu Tutor
                    <ul style={{ marginLeft: 20 }}>
                      <li>Lo elegimos según tus objetivos y preferencias</li>
                      <li>Podes cambiarlo cuando quieras!</li>
                      <li>
                        un guía y mentor de apoyo que acompaña a los Mai@s para que asuman la
                        responsabilidad de su bienestar y el logro de sus objetivos.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Seguimiento y apoyo virtual por nuestro equipo
                    <ul style={{ marginLeft: 20 }}>
                      <li>En la frecuencia que te sirva</li>
                      <li>y la forma (mensajes o llamados) que vos prefieras!</li>
                    </ul>
                  </li>
                </ul>
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Comunidad
                    <ul style={{ marginLeft: 20 }}>
                      <li>Grupo de pares</li>
                      <li>Virtual con todos los Mai@s</li>
                    </ul>
                  </li>
                </ul>
                <ul style={{ marginLeft: 20 }}>
                  <li>Especialistas curados</li>
                </ul>
              </FaqCollapse>
              <FaqCollapse
                title="¿QUÉ ESPERAR DEL PRIMER ENCUENTRO?"
                open={collapse === 2}
                onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
              >
                ¡El objetivo de nuestro primer encuentro es conocernos! Un miembro de nuestro equipo
                tendrá una videollamada para{' '}
                <strong>conocerte e identificar las áreas de mejora</strong>, haciendo foco en{' '}
                <strong>una recomendación específica</strong> para comenzar a trabajar de inmediato.
                <br />
                <br />
                Con la información obtenida podremos asignar el <strong>Tutor</strong> más adecuado
                para las necesidades que se presenten. Y si aún quedan dudas, podrán ser evacuadas
                en la charla.
                <br />
                <br />
                <strong>¿Que es un Tutor del Bienestar?</strong>
                <br />
                <br />
                Llamamos Tutor al profesional que mejor se adecue a tu personalidad, preferencias y
                áreas de trabajo. Velará por tu bienestar a lo largo de todo el proceso. Se encarga
                de proteger tus objetivos, guiarte en la incertidumbre y mantener tu compromiso alto
                a lo largo del camino.
              </FaqCollapse>
              <FaqCollapse
                title="¿CÓMO CONTINÚA EL PROCESO?"
                open={collapse === 3}
                onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
              >
                Nuestro equipo hará <strong>seguimiento a través de Whatsapp</strong> para
                profundizar el trabajo de hábitos y mantenerte en el camino del progreso. Luego
                tendrá lugar el #SegundoEncuentro donde <strong>conocerás a TU Tutor*</strong> para
                trabajar codo a codo.
                <br />
                <br />
                *podrás cambiar de Tutor en cualquier momento si quisieras; para obtener una nueva
                perspectiva o estilo
              </FaqCollapse>
            </MKBox>

            <MKBox p={3} mt={-3} mx={0} ml={1}>
              <MKTypography variant="h5" color="black">
                Costos y Condiciones de Pago
              </MKTypography>
            </MKBox>
            <MKBox py={0} px={3} ml={3}>
              <FaqCollapse
                title="¿CUANTO CUESTA?"
                open={collapse === 4}
                onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
              >
                No hay cargos previo al primer encuentro. Confiamos plenamente en el valor que
                entregamos, por eso proponemos un pago al final del proceso. Si estás conforme y
                decidís continuar en Sandbox, en este momento contamos con un valor sugerido con
                descuento por tratarse de nuestro periodo beta testing donde solo buscamos cubrir
                nuestros <strong>costos</strong>. El mismo incluye el acompañamiento del primer mes
                y la videollamada inicial con tu Tutor.
              </FaqCollapse>
              <FaqCollapse
                title="¿CUANDO SE PAGA?"
                open={collapse === 5}
                onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
              >
                No pagas hasta finalizado el primer encuentro y cumplidas (o superadas ;) las
                expectativas. Luego nos contactamos personalmente por whatsapp y te contamos los
                medios y formas de pago.
              </FaqCollapse>
              <FaqCollapse
                title="¿QUÉ SIGNIFICA VALOR SUGERIDO? ¿HAY DESCUENTOS? "
                open={collapse === 6}
                onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
              >
                Es nuestra visión impactar la vida de millones de personas, pero entendemos que las
                posibilidades de cada una pueden ser distintas. Si el valor que proponemos te genera
                dificultades, la comunidad Sandbox te puede ayudar :)
              </FaqCollapse>
            </MKBox>

            <MKBox p={3} mt={-3} mx={0} ml={1}>
              <MKTypography variant="h5" color="black">
                Otros
              </MKTypography>
            </MKBox>
            <MKBox py={0} px={3} ml={3}>
              <FaqCollapse
                title="¿QUIÉN TIENE ACCESO A MIS DATOS?"
                open={collapse === 7}
                onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
              >
                Solo nosotros tenemos acceso a tu información, ya que es confidencial. Para tu
                tranquilidad, <strong>no compartimos ni vendemos información con terceros.</strong>
              </FaqCollapse>
              <FaqCollapse
                title="¿TENES MÁS DUDAS?"
                open={collapse === 8}
                onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
              >
                Nuestro canal directo de WhatsApp se encuentra disponible para evacuar cualquier
                duda que pueda surgir.
              </FaqCollapse>
            </MKBox>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Faq;
