/* eslint-disable no-console */
// import { Config } from 'vs-core';

// export const ENV = Config.getEnvConfig('REACT_APP_ENV');
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_URL_GET_ENTERPRISES = process.env.REACT_APP_API_URL_GET_ENTERPRISES;
export const API_URL_GET_USER = process.env.REACT_APP_API_URL_GET_USER;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_MEASURAMENT_ID = process.env.REACT_APP_FIREBASE_MEASURAMENT_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

export const API_URL_LEADS = process.env.REACT_APP_API_URL_LEADS;
export const API_URL_PRODUCTS = process.env.REACT_APP_API_URL_PRODUCTS;
export const API_URL_PROVIDERS = process.env.REACT_APP_API_URL_PROVIDERS;
export const API_URL_INSTRUCTORS = process.env.REACT_APP_API_URL_INSTRUCTORS;
