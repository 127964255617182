import { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import { ENVIRONMENT } from 'config/appConfig';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

import WhatsappButton from 'DS/components/WhatsappButton';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKInput from 'DS/components/MKInput';
import MKTypography from 'DS/components/MKTypography';
import MKButton from 'DS/components/MKButton';

// Images
import bgImage from 'assets/images/bg-vector.png';
import bgWorld from 'assets/images/WorldMap.png';
import FlagUs from 'assets/images/Flags-Us.png';
import FlagEs from 'assets/images/Flags-Es.png';
import FlagAr from 'assets/images/Flags-Ar.png';
import { analyticsTag } from 'helpers/coreHelper';

function ContactUs() {
  const Background = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
  };
  const BgMap = {
    backgroundImage: {
      sm: 'none',
      md: `url(${bgWorld})`,
    },
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: {
      sm: '0',
      md: '500px',
    },
  };

  const CardsStyles = {
    minWidth: {
      xs: '100%',
      sm: '100%',
      md: '264px',
    },

    background: '#FFFEF8',

    border: '1px solid #07092B',
    boxShadow: '-8px 8px 0px 0px',
    marginTop: '-8px',
    marginLeft: '8px',
    marginRight: '-8px',
    borderRadius: '4px',
    textAlign: 'left',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: {
      sm: 'relative',
      md: 'absolute',
    },
    marginBottom: '16px',
  };
  return (
    <MKBox
      component="section"
      py={{ xs: 3, md: 12 }}
      width="100%"
      sx={{
        position: 'relative',
        display: 'grid',
        placeItems: 'center',
        ...Background,
      }}
    >
      <CustomContainer>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              position: 'relative',
              display: 'grid',
              placeItems: 'center',
              ...BgMap,
            }}
          >
            <MKBox
              sx={{
                ...CardsStyles,
                borderColor: '#5D5DFB',
                color: '#5D5DFB',
                bottom: {
                  sm: 'auto',
                  md: '50px',
                },
                left: {
                  sm: 'auto',
                  md: '31%',
                },
              }}
            >
              <MKBox paddingLeft="16px">
                <MKTypography variant="teamname" color="primary" paddingBottom="8px">
                  HQ Buenos Aires
                </MKTypography>
                <MKTypography variant="teampos" color="team">
                  Buenos Aires
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox
              sx={{
                ...CardsStyles,
                borderColor: '#DB6639',
                color: '#DB6639',
                boxShadow: '8px 8px 0px 0px',
                marginTop: '-8px',
                marginLeft: '-8px',
                marginRight: '8px',
                top: {
                  sm: 'auto',
                  md: '150px',
                },
                left: {
                  sm: 'auto',
                  md: '7%',
                },
              }}
            >
              <MKBox paddingLeft="16px">
                <MKTypography variant="teamname" color="secondary" paddingBottom="8px">
                  HQ Miami
                </MKTypography>
                <MKTypography variant="teampos" color="team">
                  ¡Coming Soon! - 2024
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox
              sx={{
                ...CardsStyles,
                top: {
                  sm: 'auto',
                  md: '130px',
                },
                left: {
                  sm: 'auto',
                  md: '50%',
                },
              }}
            >
              <MKBox paddingLeft="16px">
                <MKTypography variant="teamname" color="dark" paddingBottom="8px">
                  HQ Madrid
                </MKTypography>
                <MKTypography variant="teampos" color="team">
                  ¡Coming Soon! - 2024
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default ContactUs;
