import { ENVIRONMENT } from 'config/appConfig';

// Routes
import footerRoutes from 'footer.routes';

// Section
import Banner from './sections/Banner';
import AboutUs from './sections/AboutUs';
import Services from './sections/Services';
import LogoAreaOne from './sections/Logos';
import Faq from './sections/Faq';
import Testimonials from './sections/Testimonials';
import Team from './sections/Team';
import Cta from './sections/CallToAction';
import Vision from './sections/Vision';
import Portfolio from './sections/Portfolio';
import ContactUs from './sections/ContactUs';

import DefaultFooter from 'DS/complex/Footers/DefaultFooter';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

// HelpCenter page components
import { useRouter } from 'helpers/useRouter';

function Landing() {
  const router = useRouter();

  return (
    <>
      <Banner />
      <AboutUs />
      <Services />
      <Vision />
      <Portfolio />
      {/* <Testimonials /> */}
      <Cta />
      {/* <Team /> */}
      {/* {ENVIRONMENT === 'local' && <Faq />} */}
      {/* <ContactUs /> */}
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Landing;
