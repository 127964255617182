// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';
import Icon from '@mui/material/Icon';

import arrow from 'assets/images/Arrow-Left.svg';

function ColoredBackgroundCard({
  color,
  image,
  label,
  title,
  description,
  action,
  icon,
  iconText,
}) {
  return (
    <Card
      onClick={() => {
        window.open(action.route);
      }}
      sx={({ palette: { gradients }, borders: { borderRadius } }) => ({
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: borderRadius.xl,
        boxShadow: '0',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        placeItems: 'center',
        backgroundColor: 'transparent',
        padding: 3,
        '&:hover, &:focus': {
          cursor: 'pointer',
          backgroundColor: gradients[color] && gradients[color].main,
          h4: {
            color: color !== 'terciary' && 'white!important',
          },
          '.paragraf, span': {
            color: color !== 'terciary' && 'white!important',
          },
          a: {
            color: color !== 'terciary' && 'white!important',
            img: {
              filter: color !== 'terciary' && 'invert(1)',
            },
          },
        },
        '@media (max-width: 768px)': {
          backgroundColor: gradients[color] && gradients[color].main,
          h4: {
            color: color !== 'terciary' && 'white!important',
          },
          '.paragraf, span': {
            color: color !== 'terciary' && 'white!important',
          },
          a: {
            color: color !== 'terciary' && 'white!important',
            img: {
              filter: color !== 'terciary' && 'invert(1)',
            },
          },
        },
      })}
    >
      <MKBox>
        {label && (
          <MKTypography
            display="block"
            variant="caption"
            color={'text'}
            textTransform="uppercase"
            opacity={0.8}
            fontWeight="bold"
            mb={2}
          >
            {label}
          </MKTypography>
        )}
        <MKBox display="inline-flex" gap="16px" alignItems="center">
          {iconText && <Icon fontSize="medium">{iconText}</Icon>}
          {icon && <MKBox component="img" src={icon} maxWidth="2rem" />}
          <MKTypography variant="h4" color={'dark'}>
            {title}
          </MKTypography>
        </MKBox>
        <MKTypography className="paragraf" variant="body2" color={'text'} mb={3}>
          {description}
        </MKTypography>
      </MKBox>
      <MKBox>
        {image && (
          <MKBox
            component="img"
            src={image}
            alt={title}
            width="100%"
            position="relative"
            zIndex={1}
          />
        )}

        {action.type === 'internal' ? (
          <MKTypography
            component={Link}
            to={action.route}
            variant="body2"
            display="inline-flex"
            gap="8px"
          >
            {action.label} <MKBox component="img" src={arrow} maxWidth="3rem" />
          </MKTypography>
        ) : (
          <MKTypography
            component="a"
            // href={action.route}
            // target="_blank"
            // rel="noreferrer"
            color={'dark'}
            variant="body2"
          >
            {action.label}
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of ColoredBackgroundCard
ColoredBackgroundCard.defaultProps = {
  color: 'info',
  label: '',
};

// Typechecking props for the ColoredBackgroundCard
ColoredBackgroundCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ColoredBackgroundCard;
