import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

import { UIControllerProvider } from 'contexts/ui';

ReactDOM.render(
  <BrowserRouter>
    <UIControllerProvider>
      <App />
    </UIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
