// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

import FlagAr from 'assets/images/Flags-Ar.png';

function TransparentBlogCard({ iconText, title, description, action }) {
  const cardActionStyles = {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',

    '& .material-icons, .material-icons-round,': {
      transform: `translateX(2px)`,
      transition: 'transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)',
    },

    '&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round':
      {
        transform: `translateX(6px)`,
      },
  };

  return (
    <Card
      sx={{
        background: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',
      }}
    >
      <MKBox pt={{ xs: 1, md: 2 }} pb={{ xs: 1, md: 3 }} textAlign={{ xs: 'left', md: 'center' }}>
        <Link to={action.route} sx={cardActionStyles}>
          <MKTypography variant="services" color="text" gutterBottom>
            <Icon style={{ fontSize: 130, marginRight: 40, paddingTop: 5 }}>{iconText}</Icon>
            {title}
          </MKTypography>
        </Link>
        <MKTypography variant="body2" component="p" color="text" mb={{ xs: 1, md: 2 }}>
          {description}
        </MKTypography>
        <MKTypography
          component={Link}
          to={action.route}
          variant="body2"
          fontWeight="regular"
          color={action.color}
          textTransform="capitalize"
          sx={cardActionStyles}
        >
          {action.label}
        </MKTypography>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the TransparentBlogCard
TransparentBlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']),
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'inherit',
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
      'text',
    ]).isRequired,
  }).isRequired,
};

export default TransparentBlogCard;
