// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Kit 2 PRO React components
import Container from '@mui/material/Container';

function CustomContainer({ children }) {
  return (
    <Container sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
      {children}
    </Container>
  );
}

CustomContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomContainer;
