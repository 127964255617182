import { ENVIRONMENT } from 'config/appConfig';

// Routes
import footerRoutes from 'footer.routes';

// Section
import Banner from './sections/Banner';
import Services from './sections/Services';
import LogoAreaOne from './sections/Logos';
import Faq from './sections/Faq';
import Testimonials from './sections/Testimonials';
import Team from './sections/Team';
import Cta from '../venturesLanding/sections/CallToAction';
import ContactUs from '../shared/sections/ContactUs';

import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

// HelpCenter page components
import { useRouter } from 'helpers/useRouter';

function Landing() {
  const router = useRouter();

  return (
    <>
      <Banner />
      <Services />
      {/* <AboutUs /> */}

      <Team />
      <Cta />
      <LogoAreaOne />
      {/* {ENVIRONMENT === 'local' && <Faq />} */}
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Landing;
