import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// SwiperJS

import CustomContainer from 'DS/components/Container';

// SwiperJS react components

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Material Kit 2 PRO React components
import ColoredBackgroundCard from 'DS/complex/Cards/BackgroundCards/ColoredBackgroundCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images
import aceleratorIcon from 'assets/images/acelerator-icon.png';
import bootcampIcon from 'assets/images/bootcamp-icon.png';
import incubatorIcon from 'assets/images/incubator-icon.png';
import image from 'assets/images/thumb-about.png';

import { APPLY_BOOTCAMP_URL, APPLY_INCUBATOR_URL, APPLY_ACCELERATOR_URL } from 'helpers/coreHelper';

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function AboutUs() {
  const isXs = useMediaQuery('(max-width: 720px)');

  function List({ icon, text }) {
    return (
      <Grid item xs={12}>
        <MKBox mb={1}>
          <MKTypography display="inline" variant="body1" color="text2">
            <Icon>{icon}</Icon>
          </MKTypography>
          <MKTypography display="inline" variant="body1" ml={1} color="text2">
            {text}
          </MKTypography>
        </MKBox>
      </Grid>
    );
  }

  return (
    <MKBox component="section" pt={12} pb={0}>
      <CustomContainer>
        <MKBox pt={6} pb={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={7} a flexDirection="column">
              <MKTypography variant="flywheel" color="primary">
                · ABOUT US
              </MKTypography>
              <MKTypography variant="h1" color="text" mb={1}>
                <b>What</b> we do
              </MKTypography>
              <MKTypography variant="body1" color="text">
                AI-Based engineering, from tailor-made development to SaaS solutions.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} lg={3}>
            <ColoredBackgroundCard
              title={'AI'}
              icon={null}
              image={image}
              color="primary"
              description="Looking on how to catch the wave of artificial intelligence ? benefit from the efficiencies achieved through the implementation of AI or create new businesses with the power of this new technology."
              action={{
                type: 'external',
                route: APPLY_BOOTCAMP_URL,
                label: 'Explore AI',
              }}
              isVisible={isXs}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ColoredBackgroundCard
              title="Development"
              color="secondary"
              icon={null}
              description={
                <>
                  Work with the top software engineers on scalable and innovative solutions.
                  <br />
                  Integrations, Mobile, Web and Blockchain solutions.
                </>
              }
              image={image}
              isVisible={isXs}
              action={{
                type: 'external',
                route: APPLY_INCUBATOR_URL,
                label: 'Explore Development',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ColoredBackgroundCard
              title="Consulting"
              color="terciary"
              image={image}
              icon={null}
              description="Take advantage from our experts, industry connections, and essential resources for rapid growth."
              isVisible={isXs}
              action={{
                type: 'external',
                route: APPLY_ACCELERATOR_URL,
                label: 'Explore Consulting',
              }}
            />
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default AboutUs;
