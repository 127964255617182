import { useEffect, useRef, useState } from 'react';
import Rellax from 'rellax';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Stack from '@mui/material/Stack';
import MuiAvatarGroup from '@mui/material/AvatarGroup';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';
import WhatsappButton from 'DS/components/WhatsappButton';
import MKAvatar from 'DS/components/MKAvatar';

// Images Header
import headerImg from 'assets/images/technology.jpeg';
import starBG from 'assets/images/Star-header.png';

// Images Avatars
import team1 from 'assets/images/founders/constanza.jpg';
import team2 from 'assets/images/founders/diego.jpg';
import team3 from 'assets/images/founders/yamila.jpg';
import team4 from 'assets/images/founders/santiago.jpg';
import { analyticsTag, APPLY_MAIN_URL } from 'helpers/coreHelper';

function Banner({ image, label, title, description, cards, magicParam }) {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
          <Grid container spacing={1} sx={{ mt: { xs: '20px', sm: '60px' }, px: { xs: 1, lg: 0 } }}>
            <Grid item xs={12} lg={7} alignItems="flex-start" flexDirection="column">
              <MKTypography variant="flywheel" color="primary">
                · WE ARE YODAI
              </MKTypography>
              <MKTypography variant="h1" color="text" fontWeight="bold">
                The one-stop shop for all technology needs
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Simple, scalable and ready to use solutions
              </MKTypography>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} mt={3}>
                <MKButton
                  color="dark"
                  onClick={() => {
                    analyticsTag({
                      event: 'purchase',
                    });
                    // scrollToContactForm();

                    // router.push('/onboarding');
                    window.location = APPLY_MAIN_URL;
                  }}
                  size="large"
                  mx={4}
                >
                  Lets talk
                </MKButton>
                <Stack direction="row" alingItems="center" justifyContent={'center'}>
                  <MuiAvatarGroup spacing={12}>
                    <MKAvatar src={team1} alt="team 1" size="sm" />
                    <MKAvatar src={team2} alt="team 2" size="sm" />
                    <MKAvatar src={team3} alt="team 3" size="sm" />
                    <MKAvatar src={team4} alt="team 4" size="sm" />
                  </MuiAvatarGroup>
                  <MKTypography variant="body2" color="text" mx={1}>
                    join our +100 successful deliveries
                  </MKTypography>
                </Stack>
              </Stack>
            </Grid>
            {/* <Grid item xs={12} lg={5}>
              <MKBox
                borderRadius="lg"
                opacity={1}
                component="img"
                src={headerImg}
                width="100%"
                alt="img"
              />
            </Grid> */}
          </Grid>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Banner;
