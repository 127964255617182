// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
import bgImage from 'assets/images/bg-team.png';
import miche from 'assets/images/team/miche.png';
import mili from 'assets/images/team/mili.png';
import pablo from 'assets/images/team/pablo.png';

function Team() {
  const Background = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'contain',
    backgroundPositionY: 'bottom',
    backgroundRepeat: 'no-repeat',
  };

  const CardsStyles = {
    background: '#FAFAFA',
    border: '1px solid #000000',
    borderRadius: '56px',
    position: 'absolute',
    textAlign: 'center',
    padding: '16px 0',
    width: '264px',
  };

  return (
    <MKBox
      display="flex"
      sx={{
        pt: { xs: 1, sm: 1, md: 6 },
        ...Background,
      }}
    >
      <CustomContainer>
        <MKBox pt={3} pb={8} textAlign="center">
          <MKTypography variant="d2" color="text">
            Core team
          </MKTypography>
          <br />
          <MKTypography variant="body1" color="text">
            Our mission is to be the best co-equipers for early stage, tech startups seeking for
            impact.
          </MKTypography>
        </MKBox>
        <Grid container sx={{ mt: 5, marginBottom: '-10px' }} justifyContent="center" gap={5}>
          <Grid
            item
            position="relative"
            xs={12}
            sm={12}
            md={3}
            justifyContent="center"
            display="flex"
            order={{ xs: 1, sm: 0 }}
          >
            <MKBox component="img" src={miche} alt="miche" width={{ xs: '160px', sm: '264px' }} />
            <MKBox
              sx={{
                ...CardsStyles,
                bottom: { xs: '0', sm: '0', md: '56px' },
                left: { xs: 'auto', sm: 'auto', md: '-40px' },
              }}
            >
              <MKTypography variant="teamname" color="teamtitle" paddingBottom="8px">
                Miche Abdala
              </MKTypography>
              <MKTypography variant="teampos" color="team">
                Co-founder & CTO
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid
            item
            position="relative"
            xs={12}
            sm={12}
            md={3}
            justifyContent="center"
            display="flex"
            order={{ xs: 0, sm: 1 }}
          >
            <MKBox component="img" src={pablo} alt="pablo" width={{ xs: '160px', sm: '264px' }} />
            <MKBox
              sx={{
                ...CardsStyles,
                bottom: { xs: '0', sm: '0', md: '40px' },
              }}
            >
              <MKTypography variant="teamname" color="teamtitle" paddingBottom="8px">
                Pablo Gutiérrez O.
              </MKTypography>
              <MKTypography variant="teampos" color="team">
                Co-founder & CEO
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid
            item
            position="relative"
            xs={12}
            sm={12}
            md={3}
            justifyContent="center"
            display="flex"
            order={2}
          >
            <MKBox component="img" src={mili} alt="mili" width={{ xs: '160px', sm: '264px' }} />
            <MKBox
              sx={{
                ...CardsStyles,
                bottom: { xs: '0', sm: '0', md: '56px' },
                right: { xs: 'auto', sm: 'auto', md: '-40px' },
              }}
            >
              <MKTypography variant="teamname" color="teamtitle" paddingBottom="8px">
                Mili Gutiérrez O.
              </MKTypography>
              <MKTypography variant="teampos" color="team">
                Co-founder & COO
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Team;
