import { useEffect, useRef, useState } from 'react';
import Rellax from 'rellax';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Stack from '@mui/material/Stack';
import MuiAvatarGroup from '@mui/material/AvatarGroup';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';
import WhatsappButton from 'DS/components/WhatsappButton';
import MKAvatar from 'DS/components/MKAvatar';

// Images Header
import headerImg from 'assets/images/img-header.png';
import sBG from 'assets/images/s-bg.png';
import starBG from 'assets/images/Star-header.png';

// Images Avatars
import team1 from 'assets/images/founders/constanza.jpg';
import team2 from 'assets/images/founders/diego.jpg';
import team3 from 'assets/images/founders/yamila.jpg';
import team4 from 'assets/images/founders/santiago.jpg';

function Banner({ image, label, title, description, cards, magicParam }) {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
          <Grid
            container
            spacing={1}
            sx={{ mt: { xs: '80px', sm: '160px' }, px: { xs: 1, lg: 0 } }}
          >
            <Grid item xs={12} lg={12} alignItems="flex-start" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Building the <b>world</b>
                where we'd like to live in.
              </MKTypography>
              <MKTypography variant="body1" color="text" style={{ fontSize: 25 }}>
                Koi is an innovative platform where we seek to collectively build a more connected
                future.. with ourselves, others and the world.
              </MKTypography>
            </Grid>
          </Grid>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Banner;
