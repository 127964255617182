import { useRef, useEffect } from 'react';
import Rellax from 'rellax';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import TransparentBlogCard from 'DS/complex/Cards/BlogCards/TransparentBlogCard';

// Services Images
import TechAI from 'assets/images/place/tech_ai.jpg';
import TechWebApps from 'assets/images/place/tech_webapps.jpg';
import TechMobile from 'assets/images/place/tech_mobile.jpg';
import TechBlockchain from 'assets/images/place/tech_blockchain.jpg';
import TechIntegration from 'assets/images/place/tech_integration.jpg';
import TechCloud from 'assets/images/place/tech_cloud.jpg';

function Services() {
  return (
    <MKBox
      width="100%"
      pb={4}
      sx={{
        position: 'relative',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CustomContainer>
        <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="flywheel" color="secondary">
                · OUR EXPERTISE
              </MKTypography>
              <MKTypography variant="h1" color="text" mb={1}>
                <b>How</b> we do it
              </MKTypography>
              <MKTypography variant="body1" color="text">
                We believe in teamwork, community, and purpose. Years of building have enabled us to
                leverage our reusable building blocks to accelerate any development.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          pb={{ xs: 0, sm: 8 }}
        >
          <Grid item xs={6} lg={2}>
            <TransparentBlogCard
              image={TechAI}
              title="AI"
              description="Embrace AI to enhance efficiency and unlock new business opportunities"
              action={{
                type: 'internal',
                route: '/',
              }}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <TransparentBlogCard
              image={TechWebApps}
              title="Web apps"
              description="Simple and scalable platforms to tackle diverse challenges"
              action={{
                type: 'internal',
                route: '/',
              }}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <TransparentBlogCard
              image={TechMobile}
              title="Mobile apps"
              description="Mobile apps with enhanced user experiences"
              action={{
                type: 'internal',
                route: '/',
              }}
            />
          </Grid>

          <Grid item xs={6} lg={2}>
            <TransparentBlogCard
              image={TechIntegration}
              title="Integration solutions"
              description="Integration between CRM, ERPs and BaaS solutions"
              action={{
                type: 'internal',
                route: '/',
              }}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <TransparentBlogCard
              image={TechCloud}
              title="BI & Data"
              description="Meet our Business inteligence team, unlocking the power of data to revolutionize your company's journey towards becoming a data-driven enterprise"
              action={{
                type: 'internal',
                route: '/',
              }}
            />
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Services;
