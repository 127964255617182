// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

import FlagAr from 'assets/images/Flags-Ar.png';

function TransparentBlogCard({ image, title, description, action }) {
  const cardActionStyles = {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',

    '& .material-icons, .material-icons-round,': {
      transform: `translateX(2px)`,
      transition: 'transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)',
    },

    '&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round':
      {
        transform: `translateX(6px)`,
      },
  };

  const StyleCard = {
    background: 'transparent',
    boxShadow: 'none',
    transition: 'all 150ms ease-in',
    '&:hover': {
      '.img-card': {
        transition: 'all 150ms ease-in',
        boxShadow: '-6px 6px 0px 0px #DB6639',
        marginTop: '-6px',
        marginLeft: '6px',
        marginRight: '-6px',
      },
      '.description': {
        bottom: '0',
        zIndex: '1',
      },
    },
  };

  const Description = {
    position: 'absolute',
    background: '#07092B',
    bottom: '100%',
    padding: '14px 15px',
  };

  const imageTemplate = (
    <MKBox
      className="img-card"
      position="relative"
      overflow="hidden"
      display="flex"
      width="155px"
      sx={{
        transition: 'all 150ms ease-in',
        height: {
          xs: '240px',
          sm: '240px',
          md: '240px',
        },
        borderRadius: '12px',
      }}
    >
      <MKBox
        component="img"
        src={image}
        alt={title}
        width="auto"
        height="100%"
        position="relative"
        zIndex={1}
        top="auto"
      />
      <MKBox
        className="description"
        sx={{
          ...Description,
        }}
      >
        <MKTypography variant="services" component="p" color="white">
          {description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );

  return (
    <Card
      sx={{
        ...StyleCard,
      }}
    >
      <MKBox textAlign="center" paddingBottom="18px">
        {action.type === 'internal' ? (
          <Link to={action.route} sx={cardActionStyles}>
            <MKTypography variant="services" color="text" alignItems="center" gutterBottom>
              {title}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer" sx={cardActionStyles}>
            <MKTypography variant="services" color="text" gutterBottom alignItems="center">
              {title}
            </MKTypography>
          </MuiLink>
        )}
      </MKBox>
      <MKBox
        textAlign="center"
        paddingBottom="18px"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {imageTemplate}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the TransparentBlogCard
TransparentBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']),
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'inherit',
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
      'text',
    ]).isRequired,
  }).isRequired,
};

export default TransparentBlogCard;
