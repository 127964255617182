// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
import DrGea from 'assets/images/logos/dr-gea.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import Dendro from 'assets/images/logos/logo-dendro.png';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Riat from 'assets/images/logos/logo-riat.png';
import Qxm from 'assets/images/logos/logo-qxm.png';
import Impulsum from 'assets/images/logos/logo-impulsum.png';

function Portfolio() {
  const logosMap = [
    {
      image: NoPausa,
      name: 'NoPausa',
      url: 'https://app.trysancho.com/companies/ynVExmg0KpbwXQlX9rBM',
    },
    {
      image: Riat,
      name: 'Riat',
    },
    {
      image: Qxm,
      name: 'Qxm',
    },
    {
      name: 'Impulsum',
    },
    {
      image: DrGea,
      name: 'DrGea',
    },
    {
      image: Aconcagua,
      name: 'Aconcagua',
    },
    {
      image: Nippy,
      name: 'Nippy',
    },
    {
      image: SomosFit,
      name: 'SomosFit',
    },
    {
      image: Maia,
      name: 'Maia',
    },
    {
      image: Dendro,
      name: 'Dendro',
    },
  ];
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '2px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  return (
    <MKBox display="flex" py={6} position="relative">
      <CustomContainer>
        <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="flywheel" color="primary">
                · PORTFOLIO
              </MKTypography>
              <MKTypography variant="h1" color="text" mb={1}>
                <b>Who</b> worked with us
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
        >
          {logosMap &&
            logosMap.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
              >
                {item.image ? (
                  <MKBox
                    className="img-card"
                    component="img"
                    src={item.image}
                    alt={item.name}
                    width="85px"
                    position="relative"
                    zIndex={1}
                    top="auto"
                  />
                ) : (
                  <MKTypography variant="h4" color="text" mb={1}>
                    {item.name}
                  </MKTypography>
                )}
              </MKBox>
            ))}
        </MKBox>
        {/* <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="body1" color="text">
                Aconcagua provides lenders the latest tech-based collaterals. This new paradigm of
                lending will: 1) increase the base of clients; 2) increase the credit size; 3)
                decrease the interest rate.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox> */}
      </CustomContainer>
    </MKBox>
  );
}

export default Portfolio;
